import React, { FC } from "react";
import { fontStacks, borders } from "../global-style";
import { BackgroundBlockAbs } from "./backgroundblockabs";
import Img, { FluidObject } from "gatsby-image";

type Props = {
  title: string;
  content?: string;
  link?: any;
  image: any;
};

export const TitleImageAndGrayBlock: FC<Props> = props => {
  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        marginBottom: "12rem",
      }}
      css={`
        @media (max-width: 1024px) {
          .header-image-cover {
            width: 100% !important;
            height: 100% !important;
            right: 0rem !important;
          }
          .headerImage {
            opacity: 0.47;
            border-radius: 0rem !important;
          }
          .topDivContainer {
            padding-top: 8.5rem !important;
          }
        }
        @media (max-width: 425px) {
          .topDivContainer {
            padding-top: 2rem !important;
          }
        }
      `}
    >
      <div
        className="topDivContainer"
        style={{
          flexBasis: "83rem",
          paddingTop: "1.5rem",
          border: borders.red,
        }}
      >
        <div
          style={{
            margin: "0 1.5rem",
          }}
        >
          <div
            style={{
              marginBottom: "2rem",
              lineHeight: "2.75rem",
              display: "flex",
              overflow: "hidden",
              width: "100%",
              maxWidth: "20rem",
              border: borders.green,
            }}
          >
            <div
              style={{
                fontFamily: fontStacks.merriweather,
                fontSize: "2rem",
                margin: "0.5rem",
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: props.title as string,
                }}
              />
            </div>
          </div>
        </div>

        <div style={{ flexGrow: 1, zIndex: 5 }}>
          <BackgroundBlockAbs
            contentText={props.content as string}
            linkText={props.link?.title as string}
            linkUrl={props.link?.url as string}
          />
        </div>
      </div>

      <div
        className="header-image-cover"
        style={{ position: "absolute", right: "3rem", width: "50%" }}
      >
        {props.image && (
          <Img
            className="headerImage"
            fluid={props.image.localFile?.childImageSharp?.fluid as FluidObject}
            style={{
              flexBasis: "50%",
              maxHeight: "40rem",
              borderRadius: "3rem 0rem 0rem 0rem",
              position: "absolute",
              width: "100%",
              zIndex: -5,
            }}
          />
        )}
      </div>
    </div>
  );
};
